export default {

    data() {
      return {

        /**
         * @author Sebastian Inman
         * @since  January 28 2020
         * This array of markers determines which
         * markers are allowed to be displayed on
         * applicator profiles and used as filter
         * options in the applicator map.
         *
         * !! Simply comment/uncomment badges as needed. !!
         *
         */

        allowedMarkers: [
          'certified',
          'distributor',
          'coming_soon_cerakote',
          // 'cerakote_trendsetter',
          'cerakote_robotics',
          // 'cerakote_multicam',
          'cerakote_high_volume'
        ]

      }
    },

    methods: {

      /**
       * @method applicatorLinks
       * @author Sebastian Inman
       * @since January 21 2020 "Created"
       * @modified January 24 2020 "Applied method to gallery details page"
       * @description Modified customer URLs to ensure they are always formatted properly.
       * @param {String} linkType - The type of URL being modified (website, facebook, instagram)
       * @param {String} linkURL - The URL string being modified
       */

      applicatorLinks(linkType, linkURL) {

        // RegEx to check for http(s) at beginning of URL.
        let URLHasProtocol = new RegExp("(http[s]?:\/\/)");

        // RegEx to check if facebook.com is in a URL.
        let hasFacebookInURL = new RegExp("(facebook\.com)");

        // RegEx to check if instagram.com is in a URL.
        let hasInstagramURL = new RegExp("(instagram\.com)");

        // RegEx to check if twitter.com is in a URL.
        let hasTwitterURL = new RegExp("(twitter\.com)");

        if(linkURL && linkURL !== "") {

          // Force lowercase URLs.
          linkURL = linkURL.toLowerCase();

          switch(linkType) {

            case "website":

              // Force website to start with HTTP if the link
              // doesn't already have a protocol in the URL.
              // We don't want to auto-assign HTTPS in case
              // the applicator's website doesn't have SSL for
              // some really weird reason.

              if (!URLHasProtocol.test(linkURL)) {

                linkURL = `http://${linkURL}`;

              }

              break;

            case "facebook":

              // Add facebook.com to the URL if it isn't already there.
              // Some Applicators only add their Facebook page name.
              // This will make sure their link works correctly.

              if (!hasFacebookInURL.test(linkURL)) {

                linkURL = `facebook.com/${linkURL}`;

              }

              // Force Facebook URL to start with HTTPS if the
              // link doesn't already have a protocol in the URL.

              if (!URLHasProtocol.test(linkURL)) {

                linkURL = `https://${linkURL}`;

              }

              break;

            case "instagram":

              // Add instagram.com to the URL if it isn't already there.
              // Some Applicators only add their Instagram page name.
              // This will make sure their link works correctly.

              if (!hasInstagramURL.test(linkURL)) {

                linkURL = `instagram.com/${linkURL}`;

              }

              // Force Instagram URL to start with HTTPS if the
              // link doesn't already have a protocol in the URL.

              if (!URLHasProtocol.test(linkURL)) {

                linkURL = `https://${linkURL}`;

              }

              break;

            case "twitter":

              // Add instagram.com to the URL if it isn't already there.
              // Some Applicators only add their Instagram page name.
              // This will make sure their link works correctly.

              if (!hasTwitterURL.test(linkURL)) {

                linkURL = `twitter.com/${linkURL}`;

              }

              // Force Instagram URL to start with HTTPS if the
              // link doesn't already have a protocol in the URL.

              if (!URLHasProtocol.test(linkURL)) {

                linkURL = `https://${linkURL}`;

              }

              break;

          }

        }

        return linkURL;

      },

      /**
       * @method reviewProjectSlug
       * @author Sebastian Inman
       * @since February 02 2020
       * @description Gets the full URL slug of the project a review belongs to.
       * @param {Number} id - The ID of the project the review belongs to.
       * @returns {String} slug - The full URL slug of the project the review belongs to.
       */

      reviewProjectSlug(id) {

        const vm = this;

        // Use just the project ID as a fallback
        // URL slug in case we can't find the full
        // URL slug for some reason.
        let slug = `/project-gallery/${id}`;

        // Loop through each of the available projects.
        Object.keys(vm.projects).map(function(key) {

          // Look for a matching project ID.
          if (String(id) === String(vm.projects[key].id)) {

            // Use the full URL slug.
            slug = vm.projects[key].url;

          }

        });

        return slug;

      },

    }

}

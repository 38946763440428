export default {

  data() {
    return {

      loadingProject: false,
      currentProject: false

    }
  },

  methods: {


    async fetchProjectById(id) {

      const { data: project } = await axios.get(`/api/proxify/domains/cerakote/projects/${id}`);
      return project;

    },


    /**
     * Fetches all media associated with the project specified by the supplied id from the API.
     * @param {number|string} id
     * @returns {Promise<*>}
     */

    async projectMedia(id) {

      const { data: media } = await axios.get(`/api/proxify/domains/cerakote/projects/${id}/media`);
      return media.data;

    },


    /**
     * Opens the project associated with the supplied id in a modal.
     * This method will first fetch the project using the supplied id,
     * then fetch all media files associated with the fetched project.
     * @param {object|number|string} project The project being viewed.
     * @returns {Promise<void>}
     */

    async openProject([ project]) {

      try {

        if([ "string", "number" ].includes(typeof project)) project = await this.fetchProjectById(project);
        if(!project.media) project.media = await this.projectMedia(project.id);
        this.currentProject = project;

        setTimeout(() => $("#galleryLightBoxModal").modal("show"), 100);

      } catch(error) {

        this.$refs.notifications.add({
          type: "error",
          icon: "exclamation-triangle",
          title: "Something went wrong",
          message: `Unable to open the selected project.
          Please contact customer support if you continue having this issue.`
        });

      }

    },


    /**
     * Opens the Edit Project modal for the specified project.
     * This method will first fetch all associated media for the project.
     * @param {object|number|string} project The project being edited.
     * @returns {Promise<void>}
     */

    async editProject([ project]) {

      try {

        if([ "string", "number" ].includes(typeof project)) project = await this.fetchProjectById(project);
        if(!project.media) project.media = await this.projectMedia(project.id);
        this.currentProject = project;

        setTimeout(() => {

          this.$refs.reloadEdit.reloadEditModal(this.currentProject);
          $("#galleryEditModal").modal("show");

        }, 100);

      } catch(error) {

        this.$refs.notifications.add({
          type: "error",
          icon: "exclamation-triangle",
          title: "Something went wrong",
          message: `Unable to edit the selected project.
          Please contact customer support if you continue having this issue.`
        });

      }

    },


    /**
     * Opens the project associated with the supplied id in a modal.
     * This method will first fetch the project using the supplied id,
     * then fetch all media files associated with the fetched project.
     * @param {object|number|string} project The project being reviewed.
     * @returns {Promise<void>}
     */

    async reviewProject([ project ]) {

      try {

        if([ "string", "number" ].includes(typeof project)) project = await this.fetchProjectById(project);
        this.currentProject = project;

        setTimeout(() => $('#applicatorReviewModal').modal('show'), 150);

      } catch(error) {

        this.$refs.notifications.add({
          type: "error",
          icon: "exclamation-triangle",
          title: "Something went wrong",
          message: `Unable to review the selected project.
          Please contact customer support if you continue having this issue.`
        });

      }

    }


  }

}

import autoload from "./autoload";
import profile from "./mixins/profile";
import ProfileFeaturedItem from "./components/user/ProfileFeaturedItem.vue";
import ProfileProjectCard from "./components/user/ProfileProjectCard.vue";
import ProfileReviewCard from "./components/user/ProfileReviewCard.vue";
import ProfileVideoCard from "./components/user/ProfileVideoCard.vue";
import GalleryProjectModal from "./components/modals/GalleryProjectModal.vue";
import GalleryVideoModal from "./components/modals/GalleryVideoModal.vue";

autoload();

window.app = new Vue({
  el: "#vue-applicator-page",

  mixins: [profile],

  components: {
    ProfileFeaturedItem,
    ProfileProjectCard,
    ProfileReviewCard,
    ProfileVideoCard,
    GalleryProjectModal,
    GalleryVideoModal,
  },

  data() {
    return {
      project: {},
      searchProcessing: false,
      applicationSubmitted: false,
    };
  },

  mounted() {
    this.initProfilePage();
  },

  computed: {
    profile() {
      return {
        ...this.applicatorProfile,
        ...{
          work_accepted: this.applicatorProfile.public_work_accepted,
          website: this.applicatorLinks(
            "website",
            this.applicatorProfile.website
          ),
          facebook_url: this.applicatorLinks(
            "facebook",
            this.applicatorProfile.facebook_url
          ),
          instagram_url: this.applicatorLinks(
            "instagram",
            this.applicatorProfile.instagram_url
          ),
          twitter: this.applicatorLinks(
            "twitter",
            this.applicatorProfile.twitter
          ),
          avatar: this.avatar,
          banner: this.banner,
          avatar_pending: this.avatarPending,
          banner_pending: this.bannerPending,
        },
      };
    },

    businessType() {
      return this.applicatorProfile.public_user_categories
        ? this.applicatorProfile.public_user_categories.filter((category) => {
            return (
              category &&
              category.business_type &&
              category.domain == "cerakote"
            );
          })[0]
        : false;
    },
  },

  methods: {
    async fetchProfileImages() {
      this.avatar = this.applicatorProfile.profile_image_url ?? false;
      this.banner = this.applicatorProfile.banner_picture_url ?? false;
    },
  },
});

export default {

  data() {
    return {

      loadingVideo: false,
      currentVideo: false

    }
  },

  methods: {


    /**
     *
     * @param {number|string} id
     * @returns {Promise<any>}
     */

    async fetchVideoById(id) {

      const { data: video } = await axios.get(`/api/proxify/me/profile/videos/${id}`);
      return video;

    },


    /**
     * Opens the project associated with the supplied id in a modal.
     * This method will first fetch the project using the supplied id,
     * then fetch all media files associated with the fetched project.
     * @param {object|number|string} video
     * @returns {Promise<void>}
     */

    async openVideo([video]) {

      try {

        this.currentVideo = ["string", "number"].includes(typeof video) ? await this.fetchVideoById(video) : video;
        setTimeout(() => $('#galleryVideoModal').modal('show'), 500);

      } catch(error) {

        this.$refs.notifications.add({
          type: "error",
          icon: "exclamation-triangle",
          message: `Unable to load the selected video.
          Please contact customer support if you continue having this issue.`
        });

      }

    }


  }

}

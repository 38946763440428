<template>
  <div class="feed-item-content grid-card review-card">
    <div class="grid-card__inner" style="pointer-events: none">
      <div data-ratio="1:1">
        <transition name="fade">
          <div v-if="isLoading" class="grid-card-loading">
            <spinner />
          </div>
        </transition>
      </div>
      <div class="grid-card-content">
        <small class="grid-card-type">Applicator Review</small>
        <h3>{{ item.title }}</h3>
        <time class="grid-card-date" :datetime="item.createdAt">
          <i class="fa fa-calendar-o"></i> {{ timestamp }}
        </time>
        <blockquote class="project-review-quote">
          <span
            class="project-review-rating"
            :title="`Rated ${item.rating} out of 5 stars`"
          >
            <i
              v-for="n in 5"
              :class="`fa ${n <= item.rating ? 'fa-star' : 'fa-star-o'}`"
              :key="`${item.id}-rating-${n}`"
            ></i>
          </span>
          <p>{{ item.body }}</p>
          <cite>{{ citation }}</cite>
        </blockquote>
        <br />
        <a
          v-if="item.project !== null"
          class="btn btn-default btn-block"
          style="pointer-events: all"
          :href="item.project.url"
          target="_blank"
          ><i class="fa fa-external-link" aria-hidden="true"></i> View
          Project</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import cards from "../../mixins/profile.cards";
import spinner from "../Spinner.vue";

export default {
  name: "profile-review-card",

  mixins: [cards],

  components: {
    spinner,
  },

  computed: {
    timestamp() {
      return new Date(this.item.createdAt).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },

    citation() {
      let output = "";

      output += this.item.name
        ? `- ${this.item.name}`
        : `- ${this.parent.user.company_name} Customer`;

      if (this.item.name && this.location) output += ` from ${this.location}`;

      return output;
    },
  },
};
</script>

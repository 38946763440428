<template>

  <svg v-if="animation === 'simple'" :width="`${size}px`" :height="`${size}px`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <rect x="0" y="0" width="100" height="100" fill="none"></rect>
    <circle cx="50" cy="50" r="40" :stroke="color" stroke-opacity="0.2" fill="none" stroke-width="8" stroke-linecap="round">
      <animate id="simple-spinner-anim-1" attributeName="opacity" from="0.5" to="1" dur="1s" begin="0s;simple-spinner-anim-2.end" />
      <animate id="simple-spinner-anim-2" attributeName="opacity" from="1" to="0.5" dur="1s" begin="simple-spinner-anim-1.end" />
    </circle>
    <circle cx="50" cy="50" r="40" :stroke="color" fill="none" stroke-width="8" stroke-linecap="round">
      <animate attributeName="stroke-dashoffset" dur="1.5s" repeatCount="indefinite" from="0" to="502" />
      <animate attributeName="stroke-dasharray" dur="1.5" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4" />
    </circle>
  </svg>

</template>

<script>
export default {

  name: "spinner",

  props: {

    animation: {
      type: String,
      default: "simple"
    },

    color: {
      type: String,
      default: "#FFFFFF"
    },

    size: {
      type: Number,
      default: 52
    }

  }

}
</script>

export default {

  methods: {

    scrollToElement(element, duration = 360) {

      let $element = $(element);
      let offset = $element.position().top;

      this.scrollToPosition(offset, duration);

    },

    scrollToBottomOfElement(element, duration = 360) {

      let $element = $(element);
      let offset = $element.position().top;
      let height = $element.outerHeight();

      this.scrollToPosition(offset + height, duration);

    },

    scrollToPosition(position, duration = 360) {

      let startingY = window.pageYOffset;
      let scrollDiff = position - startingY;
      let start;

      window.requestAnimationFrame(function step(timestamp) {

        if(!start) start = timestamp;
        let time = timestamp - start;

        let percent = Math.min(time / duration, 1) || 1;

        window.scrollTo(0, startingY + scrollDiff * percent);

        if(time < duration) {
          window.requestAnimationFrame(step);
        }

      });

    }

  }

}

<template>
  <div class="feed-item-content grid-card project-card">

    <div class="grid-card__inner" @click="action('openProject', item)" @mouseleave="toggleMenu('closed')">

      <figure class="grid-card-image" data-ratio="1:1" :style="`--card-image: url('${featuredImage}');`">

        <transition name="fade">
          <div v-if="isLoading" class="grid-card-loading">
            <spinner />
          </div>
        </transition>

        <div class="grid-card-image-background"></div>

        <div class="grid-card-actions" v-if="owned">

          <button @click.stop="toggleMenu" :title="`${menuOpen ? 'Close' : 'Open'} menu`" :aria-selected="menuOpen"
            :disabled="isLoading">
            <i v-if="!menuOpen" class="fa fa-bars"></i>
            <span v-else>×</span>
          </button>

          <div class="grid-card-actions-menu" :aria-hidden="!menuOpen">

            <button v-if="!isPinned" @click.stop="action('pinFeedItem', 'projects', item.id)"
              :disabled="!parent.canPinItems || ['needs_review', 'reviewed_denied'].includes(item.approval_type)">
              <i class="fa fa-thumb-tack"></i>
              <span>Pin this project</span>
            </button>

            <button v-else @click.stop="action('unpinFeedItem', 'projects', item.id)">
              <i class="fa fa-thumb-tack"></i>
              <span>Unpin this project</span>
            </button>

            <button @click.stop="action('editProject', item)" :disabled="['needs_review'].includes(item.approval_type)">
              <i class="fa fa-edit"></i> Edit this project
            </button>

            <!-- <button v-if="!isFeaturedOnMap" @click.stop="action('featureProjectOnMap', 'projects', item.id)" :disabled="['needs_review', 'reviewed_denied'].includes(item.approval_type) || $root.featuredProjects.length >= $root.featuredProjectsLimit">
              <i class="fa fa-star"></i> Feature project on map
            </button>

            <button v-else @click.stop="action('unfeatureProjectOnMap', 'projects', item.id)">
              <i class="fa fa-star-o"></i> Unfeature project from map
            </button> -->

            <button @click.stop="action('reviewProject', item)"
              :disabled="item.has_review || item.review_requested || ['needs_review', 'reviewed_denied'].includes(item.approval_type)">
              <i class="fa fa-comment"></i> Request a review
            </button>

            <button @click.stop="action('deleteFeedItem', 'projects', item.id)" class="danger">
              <i class="fa fa-trash"></i> Delete this project
            </button>

          </div>

        </div>

      </figure>

      <div class="grid-card-content">

        <small class="grid-card-type">Project</small>

        <div v-if="owned" class="grid-card-status">
          <transition name="fade">
            <span v-if="isPinned" class="status info">
              Pinned
            </span>
          </transition>
          <transition name="fade">
            <span v-if="isFeaturedOnMap" class="status pending">
              Featured on Map
            </span>
          </transition>
          <transition name="fade">
            <span v-if="item.approval_type === 'needs_review'" class="status pending">
              Pending Approval
            </span>
          </transition>
          <transition name="fade">
            <span v-if="item.approval_type === 'reviewed_denied'" class="status denied">
              Rejected
            </span>
          </transition>
          <transition name="fade">
            <span v-if="item.approval_type === 'reviewed_approved'" class="status approved">
              Approved
            </span>
          </transition>
          <transition name="fade">
            <span v-if="item.review_requested" class="status pending">
              Review Requested
            </span>
          </transition>
        </div>

        <h3>{{ item.name }}</h3>
        <time class="grid-card-date" :datetime="item.created_at">
          <i class="fa fa-calendar-o"></i> {{ timestamp }}
        </time>

        <p v-if="item.description && item.is_description_visible">{{ item.description }}</p>

      </div>

    </div>

  </div>
</template>

<script>
import cards from "../../mixins/profile.cards";
import spinner from '../Spinner.vue';

export default {
  name: "profile-project-card",

  mixins: [cards],

  components: { spinner },

  methods: {

    async featureProject() {

      this.toggleMenu("closed");
      // await this.parent.featureProject(this.item.id);

    }

  }

}
</script>

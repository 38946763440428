export default {

  data() {
    return {

      loadingReview: false,
      currentReview: false

    }
  },

  methods: {


    /**
     * Adds the specified review to the applicators Featured Work feed.
     * @param {any} review The review being added to the Featured Work feed.
     * @param {any} project
     * @returns {Promise<void>}
     */

    async pinReview(review, project) {

      try {

        await axios.put(`/api/proxify/projects/${project.id}/pin`);
        await this.addFeedItem("pinned", project);

        this.feeds.reviews.items[`reviews_${review.id}`].pinned = true;

        this.$refs.notifications.add({
          type: "success",
          icon: "check",
          message: `The project "${project.name}" was added to your Featured Work feed.`
        });

      } catch(error) {

        console.error(error);

        this.$refs.notifications.add({
          type: "error",
          icon: "exclamation-triangle",
          message: `Unable to add the project "${project.name}" to your Featured Work feed.
          Please contact customer support if you continue having this issue.`
        });

      }

    },


    /**
     * Removes the specified review from the applicators Featured Work feed.
     * @param {any} review The review being removed from the Featured Work feed.
     * @param {any} project
     * @returns {Promise<void>}
     */

    async unpinReview(review, project) {

      try {

        await axios.delete(`/api/proxify/projects/${project.id}/pin`);
        await this.removeFeedItem(["pinned", project.id]);

        this.feeds.reviews.items[`reviews_${review.id}`].pinned = false;

        this.$refs.notifications.add({
          type: "success",
          icon: "check",
          message: `The project "${project.name}" was removed from your Featured Work feed.`
        });

      } catch(error) {

        this.$refs.notifications.add({
          type: "error",
          icon: "exclamation-triangle",
          message: `Unable to remove the project "${project.name}" from your Featured Work feed.
          Please contact customer support if you continue having this issue.`
        });

      }

    },


    /**
     * Deletes the project associated with the provided id.
     * A confirmation prompt will display asking the user to confirm the deletion
     * of the project before making the delete request to the API.
     * @param {any} review The key index of the project in the projects feed.
     * @param {any} project
     * @returns {Promise<void>}
     */

    async deleteReview(review, project) {

      if(confirm(`Are you sure you want to delete the project "${project.name}"?`)) {

        try {

          // await axios.delete(`/api/proxify/domains/cerakote/projects/${review.project_id}`);
          await this.removeFeedItem(["reviews", review.id]);

          this.$refs.notifications.add({
            type: "success",
            icon: "check",
            message: `The project "${project.name}" was successfully deleted.`
          });

        } catch(error) {

          this.$refs.notifications.add({
            type: "error",
            icon: "exclamation-triangle",
            message: `Unable to delete the project "${project.name}".
            Please contact customer support if you continue having this issue.`
          });

        }

      }

    }

  }

}

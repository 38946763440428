<template>
  <modal id="applicatorReviewModal">
    <template slot="header">
      <div class="modal-header-content">
        <div class="modal-header-title">
          <h2>Add A Review</h2>
          <small
            >Submit a profile review to {{ $root.domainName }} for review</small
          >
        </div>
        <button
          type="button"
          class="modal-header-close"
          data-dismiss="modal"
          aria-label="Close"
          @click="resetModal()"
        >
          ×
        </button>
      </div>
    </template>

    <div class="alert alert-danger color-error msgblock" v-if="hasErrors">
      <label
        ><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;{{
          formErrors
        }}</label
      >
    </div>

    <div class="alert alert-danger msgblock" v-show="errors.any()">
      <span v-for="error in errors.all()" :key="error.index">
        {{ error }} <br />
      </span>
    </div>

    <form enctype="multipart/form-data" v-show="!isLoading">
      <div class="modal-form">
        <fieldset>
          <div class="modal-form-field">
            <label class="modal-form-label">
              <strong>First name (required):</strong>
              <div class="modal-form-input">
                <input
                  type="text"
                  name="first-name"
                  class="form-control"
                  placeholder="John"
                  v-model="form.firstName"
                  v-validate="'required'"
                />
                <span v-show="errors.has('first-name')" class="text-danger">{{
                  errors.first("first-name")
                }}</span>
              </div>
            </label>
          </div>
          <div class="modal-form-field">
            <label class="modal-form-label">
              <strong>Last name (required):</strong>
              <div class="modal-form-input">
                <input
                  type="text"
                  name="last-name"
                  class="form-control"
                  placeholder="Doe"
                  v-model="form.lastName"
                  v-validate="'required'"
                />
                <span v-show="errors.has('last-name')" class="text-danger">{{
                  errors.first("last-name")
                }}</span>
              </div>
            </label>
          </div>
          <div class="modal-form-field">
            <label class="modal-form-label">
              <strong>Email (required):</strong>
              <div class="modal-form-input">
                <input
                  type="text"
                  name="email"
                  class="form-control"
                  placeholder="john-doe@email.com"
                  v-model="form.email"
                  v-validate="'required'"
                />
                <span v-show="errors.has('email')" class="text-danger">{{
                  errors.first("email")
                }}</span>
              </div>
            </label>
          </div>
          <div
            v-if="
              $root.reviewType === 'applicatorProfile' ||
              isReviewVerificationPage
            "
          >
            <div class="modal-form-field">
              <label class="modal-form-label">
                <strong>Rating (required):</strong>
                <div class="stars">
                  <a
                    v-for="index in 5"
                    :key="index"
                    @click="updateRating(index)"
                    name="rating"
                    style="cursor: pointer"
                  >
                    <i
                      :id="'rating-' + index"
                      class="fa fa-star star-grey fa-2x"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </label>
            </div>
            <div class="modal-form-field">
              <label class="modal-form-label">
                <strong>Review Title (required - 60 char max):</strong>
                <div class="modal-form-input">
                  <input
                    type="text"
                    name="title"
                    class="form-control"
                    placeholder="A few descriptive words..."
                    v-model="form.title"
                    v-validate="'required'"
                    maxlength='60'
                  />
                  <span v-show="errors.has('title')" class="text-danger">{{
                    errors.first("title")
                  }}</span>
                </div>
              </label>
            </div>
            <div class="modal-form-field">
              <label class="modal-form-label">
                <strong>Review Body (required - 200 char max):</strong>
                <div class="modal-form-input">
                  <textarea
                    name="body"
                    class="form-control"
                    placeholder="Short summary about your experience..."
                    v-model="form.body"
                    v-validate="'required'"
                    maxlength='200'
                  ></textarea>
                  <span v-show="errors.has('body')" class="text-danger">{{
                    errors.first("body")
                  }}</span>
                </div>
              </label>
            </div>
          </div>
        </fieldset>
      </div>
    </form>

    <div class="text-center" v-show="isLoading">
      <spinner color="#F69422" />
    </div>

    <template slot="footer">
      <div class="modal-footer-actions">
        <button
          type="button"
          class="modal-footer-cancel"
          data-dismiss="modal"
          aria-label="Close"
          @click="resetModal()"
        >
          Cancel
        </button>
        <button
          v-if="
            $root.reviewType === 'applicatorProfile' || isReviewVerificationPage
          "
          @click="sendReview()"
          class="modal-footer-save"
          :disabled="errors.any() || !formIsComplete"
        >
          Submit
        </button>
        <button
          v-else
          @click="sendReviewRequest()"
          class="modal-footer-save"
          :disabled="errors.any() || !formIsComplete"
        >
          Submit
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import spinner from "../../components/Spinner.vue";

export default {
  props: {
    project: Object | false,
    isReviewVerificationPage: Boolean,
  },

  components: {
    spinner,
  },

  data() {
    return {
      isLoading: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        title: "",
        body: "",
        rating: 0,
        domain: this.$root.domain || "cerakote",
      },
      formSuccess: null,
      formErrors: null,
    };
  },

  computed: {
    formIsComplete() {
      if (this.$root.reviewType === "applicatorProfile") {
        if (
          this.form.firstName !== "" &&
          this.form.lastName !== "" &&
          this.form.email !== "" &&
          this.form.body !== "" &&
          this.form.title !== "" &&
          this.form.rating !== 0
        ) {
          return true;
        }
      } else if (
        this.form.firstName !== "" &&
        this.form.lastName !== "" &&
        this.form.email !== ""
      ) {
        return true;
      }

      return false;
    },

    hasErrors() {
      return this.formErrors;
    },
  },

  methods: {
    resetModal() {
      this.form.firstName = "";
      this.form.lastName = "";
      this.form.email = "";
      this.form.title = "";
      this.form.body = "";
      this.form.rating = 0;
      this.formErrors = null;
      this.isLoading = false;
      this.$root.reviewType = "";
      this.$nextTick(() => {
        this.errors.clear();
        this.$validator.reset();
      });
    },

    updateRating(rating) {
      this.form.rating = rating;

      for (let i = 1; i <= 5; ++i) {
        if (i <= this.form.rating) {
          $("#rating-" + i).removeClass("star-grey");
        } else {
          $("#rating-" + i).addClass("star-grey");
        }
      }
    },

    sendReviewRequest() {
      this.$validator
        .validateAll()
        .then((success) => {
          if (!success) {
            this.formErrors = "Field(s) are required.";
            return;
          }

          if (this.$root.applicatorId === "") {
            this.formErrors = "Unable to add a review to this applicator.";
            return;
          }

          if (!this.formIsComplete) {
            this.formErrors = "Please complete all of the required fields.";
            return;
          }

          const url = `/api/v2/proxify/applicators/${this.$root.applicatorId}/review-requests`;

          let payload = {
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            email: this.form.email,
            domain: this.form.domain,
          };

          if (this.$root.currentProject !== undefined || this.project !== undefined) {
            let projectId = "";

            if (Object.keys(this.project).length) {
              projectId = this.project.id;
            } else {
              projectId = this.$root.currentProject.id;
            }

            payload = {
              ...payload,
              project: { id: projectId },
            };
          }

          this.isLoading = true;

          axios
            .post(url, payload)
            .then((response) => {
              if (response.status === 200) {
                this.$root.$refs.notifications.add({
                  type: "success",
                  icon: "check",
                  title: "Your review request has been sent!",
                  message: `An email has been sent to ${this.form.email}`,
                });

                $("#applicatorReviewModal").modal("hide");
                this.resetModal();
              }
            })
            .catch((error) => {
              this.isLoading = false;

              if (Object.keys(error).length) {
                let apiMessage = error.response.data.message;

                if (apiMessage === null || apiMessage === undefined) {
                  apiMessage =
                    "Unable to submit your review. Please contact customer support if you continue having this issue.";
                }

                this.$root.$refs.notifications.add({
                  type: "error",
                  icon: "exclamation-triangle",
                  title: "Something went wrong",
                  message: apiMessage,
                });

                this.formErrors = apiMessage;
              }
            });
        })
        .catch((error) => {
          this.formErrors = "Please fill in missing fields.";
        });
    },

    sendReview() {
      this.$validator
        .validateAll()
        .then((success) => {
          if (!success) {
            this.formErrors = "Field(s) are required.";
            return;
          }

          if (this.form.rating === 0) {
            this.formErrors = "A rating is required.";
            return;
          }

          if (this.$root.applicatorId === "") {
            this.formErrors = "Unable to add a review to this applicator.";
            return;
          }

          if (!this.formIsComplete) {
            this.formErrors = "Please complete all of the required fields.";
            return;
          }

          let url = '';

          if (this.isReviewVerificationPage) {
            url = `/api/v2/proxify/applicators/${this.$root.applicatorId}/reviews/${this.$root.accessToken}`;
          } else {
            url = `/api/v2/proxify/applicators/${this.$root.applicatorId}/reviews`;
          }

          this.isLoading = true;

          axios
            .post(url, this.form)
            .then((response) => {
              if (response.status === 200) {
                this.$root.$refs.notifications.add({
                  type: "success",
                  icon: "check",
                  title: "Your review has been sent!",
                  message: `An email has been sent to ${this.form.email}`,
                });

                $("#applicatorReviewModal").modal("hide");
                this.resetModal();

                if (this.isReviewVerificationPage) {
                  setTimeout(() => {
                    window.location = "/";
                  }, 3000);
                }
              }
            })
            .catch((error) => {
              this.isLoading = false;

              if (Object.keys(error).length) {
                let apiMessage = error.response.data.message;

                if (apiMessage === null || apiMessage === undefined) {
                  apiMessage =
                    "Unable to submit your review. Please contact customer support if you continue having this issue.";
                }

                this.$root.$refs.notifications.add({
                  type: "error",
                  icon: "exclamation-triangle",
                  title: "Something went wrong",
                  message: apiMessage,
                });

                this.formErrors = apiMessage;
              }
            });
        })
        .catch((error) => {
          this.formErrors = "Please fill in missing fields.";
        });
    },
  },
};
</script>

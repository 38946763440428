<template>
  <modal id="galleryLightBoxModal">
    <div class="project-popup-content">

      <div class="project-popup-header">
        <h2>{{ project.name }}</h2>
        <button class="project-popup-nav nav-close" title="Close this window" @click="closeProjectPopup">×</button>
      </div>

      <figure class="project-popup-featured-image" :style="`--featured-image: url(${featuredImage})`">
        <button v-if="project.media && project.media.length > 1" class="project-popup-nav nav-left" @click="prevProjectImage" title="View previous image">
          <i class="fa fa-chevron-left"></i>
        </button>
        <button v-if="project.media && project.media.length > 1" class="project-popup-nav nav-right" @click="nextProjectImage" title="View next image">
          <i class="fa fa-chevron-right"></i>
        </button>
      </figure>

    </div>
    <div class="project-popup-sidebar">

      <div class="project-popup-sidebar-section">
        <p v-if="project.description && project.is_description_visible">{{ project.description }}</p>
        <a :href="project.url" title="View more details about this project">{{project.url}}</a>
      </div>

      <div class="project-popup-sidebar-section">
        <h3 class="project-popup-title">Project Images</h3>
        <ul class="project-popup-thumbnails">
          <li v-for="(media, key) in project.media" :key="`project-thumbnail-${key}`" @click="selectFeaturedImage(key)">
            <img :src="`${media.content_url}&size=100`" :aria-selected="currentMedia == key" :alt="media.name">
          </li>
        </ul>
      </div>

      <div v-if="project.colors && project.colors.length" class="project-popup-sidebar-section">
        <h3 class="project-popup-title">Project Colors</h3>
        <div class="project-popup-colors">
          <a v-for="color in project.colors" :href="color.url" :title="`View ${color.name}`" :key="`project-color-${color.sku}`">
            <img :src="color.featured_image_url || color.image_url" :alt="color.name">
            <small>{{color.name}}</small>
          </a>
        </div>
      </div>

      <div v-if="project.review && Object.keys(project.review).length" class="project-popup-sidebar-section">
        <h3 class="project-popup-title">Project Review</h3>
        <div class="project-review">
          <strong class="project-review-title">
            <div class="project-review-rating" :title="`Rated ${project.review.rating} out of 5 stars`">
              <i v-for="n in 5" :class="`fa fa-star ${n <= project.review.rating ? 'rated' : ''}`"></i>
            </div>
            {{project.review.title}}
          </strong>
          <blockquote>
            <small><cite>{{project.review.author_name}}</cite> from {{project.review.author_from}}</small>
            <p>{{project.review.review}}</p>
          </blockquote>
        </div>
      </div>

      <div class="project-popup-sidebar-section">
        <h3 class="project-popup-title">Contact {{profile.company_name}}</h3>
        <a
          class="btn btn-alt-outline"
          v-if="profile.company_phone"
          :href="`tel:${profile.company_phone}`"
          :onclick="`dataLayer.push({'applicatorId': '${profile.customer_id}', 'event': 'applicatorPhone'})`"
          >Call Us</a>
        <a
          class="btn btn-alt-outline"
          rel="noopener" target="_blank"
          :href="`https://maps.google.com/maps?daddr=${profile.geo_coords.lat},${profile.geo_coords.lon}`"
          :onclick="`dataLayer.push({'applicatorId': '${profile.customer_id}', 'event': 'applicatorDirections'})`"
          >Get Directions</a>
      </div>

    </div>
  </modal>
</template>

<script>
  export default {

    data() {
      return {
        currentMedia: 0
      }
    },

    props: {
      project: Object,
      profile: Object
    },

    computed: {

      featuredImage() {
        return this.project.media && this.project.media.length > 0 ? `${this.project.media[this.currentMedia].image_url}` : this.project.featured_image_url;
      }

    },

    mounted() {

      $(document).on('keydown', e => {
        let key = e.which || e.keyCode;
        if(key === 27) this.closeProjectPopup();
        if(key === 37) this.prevProjectImage();
        if(key === 39) this.nextProjectImage();
      });

    },

    methods: {

      selectFeaturedImage(key) {
        this.currentMedia = key;
      },

      nextProjectImage() {
        let key = this.project.media && this.currentMedia < this.project.media.length - 1 ? this.currentMedia + 1 : 0;
        this.selectFeaturedImage(key);
      },

      prevProjectImage() {
        let key = this.currentMedia > 0 ? this.currentMedia - 1 : this.project.media.length - 1;
        this.selectFeaturedImage(key);
      },

      closeProjectPopup() {
        this.$emit('lightbox-closed', null);
      }

    }
  }
</script>

<template>
  <modal id="galleryVideoModal">

    <div class="video-popup-content">

      <h2>{{currentVideo.name}}</h2>

      <button class="video-popup-nav nav-close" title="Close this window" @click="closeVideoPopup">
        <i class="fa fa-times"></i>
      </button>

      <div class="video-popup-player">
        <div class="video-popup-player-wrapper">
          <iframe :src="videoEmbedURL" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>

      <div class="video-popup-link">
        <i class="fa fa-link"></i>
        <a rel="nofollow" :href="currentVideo.video_url" title="View more details about this video">{{currentVideo.video_url}}</a>
      </div>

    </div>

    <div class="video-popup-sidebar">

      <div v-if="allVideos.length" class="video-popup-sidebar-section">
        <h3 class="video-popup-title">Applicator Videos</h3>
        <ul class="video-popup-thumbnails">
          <li v-for="item in allVideos" :key="`video-${item.id}`" @click="currentVideo = item">
            <img :src="`https://img.youtube.com/vi/${item.video_id}/hqdefault.jpg`" :aria-selected="currentVideo.id === item.id" />
          </li>
        </ul>
      </div>

      <div class="video-popup-sidebar-section">
        <h3 class="video-popup-title">Contact {{profile.company_name}}</h3>
        <a
          class="btn btn-alt-outline"
          v-if="profile.company_phone"
          :href="`tel:${profile.company_phone}`"
          :onclick="`dataLayer.push({'applicatorId': '${profile.customer_id}', 'event': 'applicatorPhone'})`"
          >Call Us</a>
        <a
          class="btn btn-alt-outline"
          rel="noopener" target="_blank"
          :href="`https://maps.google.com/maps?daddr=${profile.geo_coords.lat},${profile.geo_coords.lon}`"
          :onclick="`dataLayer.push({'applicatorId': '${profile.customer_id}', 'event': 'applicatorDirections'})`"
          >Get Directions</a>
      </div>

    </div>

  </modal>
</template>

<script>
  export default {

    data() {
      return {
        currentVideo: false
      }
    },

    props: {
      video: Object,
      profile: Object
    },

    mounted() {

      this.currentVideo = this.video;

      $(document).on('keydown', e => {
        let key = e.which || e.keyCode;
        if(key === 27) this.closeVideoPopup();
      });

    },

    computed: {

      allVideos() {

        return this.$parent.feeds.videos.items || [];

      },

      videoEmbedURL() {

        return `https://www.youtube.com/embed/${this.currentVideo.video_id}?rel=0;&autoplay=1&mute=1&loop=1&playlist=${this.currentVideo.video_id}`;

      }

    },

    methods: {

      closeVideoPopup() {

        this.$emit('lightbox-closed', null);

      }

    }

  }
</script>

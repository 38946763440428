<template>
  <div class="list-item featured-items-card">
    <button v-if="owned" class="featured-items-card-unpin" @click.prevent="action('unpinFeedItem', item.type, item.id)">
      <i class="fa fa-thumb-tack"></i>
    </button>
    <a class="featured-items-card-item" :href="link" :target="item.type === 'videos' ? 'blank' : null"
      @click.prevent="openItem()">
      <figure class="featured-items-card-thumbnail">
        <transition name="fade">
          <div v-if="isLoading" class="featured-items-card-loading">
            <spinner />
          </div>
        </transition>
        <i v-if="item.type === 'videos' && !isLoading" class="play-icon fa fa-play-circle"></i>
        <img :class="item.type" :src="thumbnail" :alt="item.name">
      </figure>
    </a>
  </div>
</template>

<script>
import cards from "../../mixins/profile.cards";
import spinner from '../Spinner.vue';

export default {

  name: "profile-featured-item",

  mixins: [cards],

  components: {
    spinner,
  },

  computed: {

    thumbnail() {

      return this.item.type === "videos"
        ? `https://img.youtube.com/vi/${this.item.video_id}/hqdefault.jpg`
        : `${this.item.featured_image_url}size=180`

    },

    link() {

      return this.item.type === "videos"
        ? `https://www.youtube.com/watch?v=${this.item.video_id}`
        : this.item.url

    }

  },

  methods: {

    async openItem() {

      await this.action(this.item.type === "videos" ? "openVideo" : "openProject", this.item);

    }

  }

}
</script>

export default {

  props: [ "index", "item", "owned" ],

  data: () => {
    return {

      menuOpen: false,
      isLoading: false

    }
  },

  computed: {

    featuredImage() {

      if (!this.item.hasOwnProperty('featured_image_url') || this.item?.featured_image_url === null) return false;
      if (this.item?.featured_image_url.endsWith("?")) return `${this.item.featured_image_url}size=360`;
      if (this.item?.featured_image_url.endsWith("&")) return `${this.item.featured_image_url}size=360`;
      if (this.item?.featured_image_url.includes("default-product-img.png")) return this.item.featured_image_url;

      return `${this.item.featured_image_url}&size=360`

    },

    parent() {

      return this.$parent.$parent;

    },

    timestamp() {

      return new Date(this.item.created_at).toLocaleDateString("en-US", {
        year: "numeric", month: "long", day: "numeric"
      });

    },

    isPinned() {

      return this.parent.itemIsPinned(this.item.type, this.item.id);

    },

    isFeaturedOnMap() {

      return this.$root.featuredProjects.includes(String(this.item.id)) ||
             this.$root.featuredProjects.includes(Number(this.item.id));

    }

  },

  methods: {

    toggleMenu(state = null) {

      if(state) {

        if(state === "open") {

          this.menuOpen = true;

        } else if(state === "closed") {

          this.menuOpen = false;

        }else{

          this.menuOpen = !this.menuOpen;

        }

      }else{

        this.menuOpen = !this.menuOpen;

      }

    },

    async action(action, ...parameters) {

      this.toggleMenu("closed");

      if(this.parent.hasOwnProperty(action) && typeof this.parent[action] === "function") {

        this.isLoading = true;
        await this.parent[action](parameters);
        this.isLoading = false;

      }

    }

  }

}

<template>
  <div class="feed-item-content grid-card video-card">

    <div class="grid-card__inner" @click="action('openVideo', item)" @mouseleave="toggleMenu('closed')">

      <figure class="grid-card-image" data-ratio="16:9"
        :style="`--card-image: url(https://img.youtube.com/vi/${item.video_id}/hqdefault.jpg);`">

        <transition name="fade">
          <div v-if="isLoading" class="grid-card-loading">
            <spinner />
          </div>
        </transition>

        <div class="grid-card-image-background"></div>

        <div class="grid-card-actions" v-if="owned">

          <button @click.stop="toggleMenu" :title="`${menuOpen ? 'Close' : 'Open'} menu`" :aria-selected="menuOpen"
            :disabled="isLoading">
            <i v-if="!menuOpen" class="fa fa-bars"></i>
            <span v-else>×</span>
          </button>

          <div class="grid-card-actions-menu" :aria-hidden="!menuOpen">

            <button v-if="!isPinned" @click.stop="action('pinFeedItem', 'videos', item.id)"
              :disabled="!parent.canPinItems || ['PENDING', 'DENIED'].includes(item.status)">
              <i class="fa fa-thumb-tack"></i>
              <span>Pin this video</span>
            </button>

            <button v-else @click.stop="action('unpinFeedItem', 'videos', item.id)">
              <i class="fa fa-thumb-tack"></i>
              <span>Unpin this video</span>
            </button>

            <button @click.stop="action('deleteFeedItem', 'videos', item.id)" class="danger">
              <i class="fa fa-trash"></i> Delete this video
            </button>

          </div>

        </div>

      </figure>

      <div class="grid-card-content">

        <small class="grid-card-type">Video</small>

        <div v-if="owned" class="grid-card-status">
          <transition name="fade">
            <span v-if="isPinned" class="status info">
              Pinned
            </span>
          </transition>
          <transition name="fade">
            <span v-if="item.status === 'PENDING'" class="status pending">
              Pending Approval
            </span>
          </transition>
          <transition name="fade">
            <span v-if="item.status === 'REJECTED'" class="status denied">
              Rejected
            </span>
          </transition>
          <transition name="fade">
            <span v-if="item.status === 'ACCEPTED'" class="status approved">
              Approved
            </span>
          </transition>
        </div>

        <h3>{{ item.name }}</h3>
        <time class="grid-card-date" :datetime="item.created_at">
          <i class="fa fa-calendar-o"></i> {{ timestamp }}
        </time>

      </div>

    </div>

  </div>
</template>

<script>
import cards from "../../mixins/profile.cards";
import spinner from '../Spinner.vue';

export default {
  name: "profile-video-card",

  mixins: [cards],

  components: {
    spinner,
  }
}
</script>

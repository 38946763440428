import auth from "./auth";
import initDom from "./init_dom";
import cart from "./cart";
import MX_helpers from "./helpers";
import MX_images from "./images";
import MX_applicator from "./applicator";
import MX_project from "./project";
import MX_url from "./url";
import profileProjects from "./profile.projects";
import profileReviews from "./profile.reviews";
import profileVideos from "./profile.videos";
import ApplicatorReviewModal from "../components/modals/ApplicatorReviewModal.vue";
import spinner from '../components/Spinner.vue'

export default {
  components: {
    ApplicatorReviewModal,
    spinner,
  },

  mixins: [
    initDom,
    auth,
    cart,
    MX_url,
    MX_images,
    MX_helpers,
    MX_applicator,
    MX_project,
    profileProjects,
    profileReviews,
    profileVideos,
  ],

  data() {
    return {
      currentView: "everything",
      views: ["everything", "projects", "videos", "reviews"],
      ceraApplicatorData: {},
      userId: window._PR?.id || "",
      user: {
        ...(window._PR.profileData || {}),
        ...{
          twitter: window._PR?.profileData?.twitter
            ? this.TwitterLink(window._PR.profileData.twitter)
            : "",
          facebook_url: window._PR?.profileData?.facebook_url
            ? this.FacebookLink(window._PR.profileData.facebook_url)
            : "",
          instagram_url: window._PR?.profileData?.instagram_url
            ? this.InstagramLink(window._PR.profileData.instagram_url)
            : "",
        },
      },
      isPublicProfile: window._PR?.isPublicProfile || false,
      applicatorProfile: window._PR?.profileData || false,
      featuredProjects: [],
      featuredProjectsLimit: 3,
      feeds: window._PR?.feeds || {},
      categories: window._PR?.userCategories || [],
      endpoints: {
        me: `/api/proxify/me`,
        feed: {
          pinned: `/api/proxify/user-public-profiles/{id}/feed`,
          projects: `/api/proxify/user-public-profiles/{id}/projects`,
          reviews: `/api/v2/proxify/applicators/{id}/reviews`,
          videos: `/api/proxify/user-public-profiles/{id}/videos`,
        },
        pin: {
          projects: "/api/proxify/projects/{id}/pin",
          reviews: "/api/proxify/projects/{id}/pin",
          videos: "/api/proxify/me/profile/videos/{id}/pin",
        },
      },
      avatar: false,
      banner: false,
      avatarPending: false,
      bannerPending: false,
      currentVideo: false,
      currentMedia: 0,
      ceraApplicatorTypes: [
        "certified",
        "on_map_cerakote",
        "coming_soon_cerakote",
        "cerakote_distributor",
        "cerakote_trendsetter",
        "cerakote_robotics",
        "cerakote_high_volume",
        "cerakote_multicam",
      ],
      feedLoaded: false,
      disableLoadingButton: false,
      reviewType: ''
    };
  },

  computed: {
    canPinItems() {
      return this.feeds.pinned.items.length < this.feeds.pinned.limit;
    },

    feedIsUpdating() {
      return (
        Object.values(this.feeds).filter((feed) => {
          return feed.updating;
        }).length > 0
      );
    },

    feedTotals() {
      return {
        everything: this.ceraApplicatorData.everything_count || 0,
        projects: this.ceraApplicatorData.project_count || 0,
        reviews: this.ceraApplicatorData.review_count || 0,
        videos: this.ceraApplicatorData.video_count || 0
      };
    },

    avatarURL() {
      if (this.avatar) return this.ImageResize(this.avatar, 200);
      if (this.isDistributor) return "/img/logo-distributor-small.png";
      if (this.profile.profile_image_url)
        return this.ImageResize(this.profile.profile_image_url, 200);
      return "/img/logo-small.png";
    },

    bannerURL() {
      if (this.banner) return this.ImageResize(this.banner, 1600);
      if (this.profile.banner_picture_url)
        return this.ImageResize(this.profile.banner_picture_url, 1600);
      return false;
    },

    isDistributor() {
      return (this.ceraApplicatorData.badges?.includes("distributor") || this.ceraApplicatorData.badges?.includes("cerakote_distributor")) ?? false;
    },

    isCertified() {
      return (this.ceraApplicatorData.badges?.includes("certified") || this.isDistributor) ?? false;
    },

    isTrendSetter() {
      return (this.ceraApplicatorData.badges?.includes("cerakote_trendsetter") ?? false);
    },

    isComingSoon() {
      return (this.ceraApplicatorData.badges?.includes("coming_soon_cerakote") ?? false);
    },

    isRobotics() {
      return (this.ceraApplicatorData.badges?.includes("cerakote_robotics") ?? false);
    },

    isMultiCam() {
      return (this.ceraApplicatorData.badges?.includes("cerakote_multicam") ?? false);
    },

    isHighVolume() {
      return (this.ceraApplicatorData.badges?.includes("cerakote_high_volume") ?? false);
    },

    isOnMap() {
      return (
        (this.ceraApplicatorData.badges?.includes("on_map_cerakote") &&
          !!this.ceraApplicatorData.geo_coords) ??
        false
      );
    },

    isApplicator() {
      return this.isComingSoon || this.isCertified;
    },

    hasSocialMedia() {
      if (
        this.ceraApplicatorData.facebook_url?.length > 0 ||
        this.ceraApplicatorData.instagram_url?.length > 0 ||
        this.ceraApplicatorData.twitter_handle?.length > 0
      ) {
        return true;
      }

      return false;
    },

    hasCompanyAddress() {
      return (
        this.user.company_address &&
        Object.keys(this.user.company_address).length &&
        (this.user.company_address.city ||
          this.user.company_address.region.name ||
          this.user.company_address.country.code)
      );
    },

    applicatorId() {
      return (
        this.ceraApplicatorData.user_id ||
        this.applicatorProfile.user_id ||
        ""
      ).toString();
    },
  },

  /**
   * Upon mounting this mixin, store the feed items that were grabbed
   * and provided by the controller.
   * @returns {Promise<void>}
   */

  async mounted() {
    /** Always ensure the feed list is an array */
    await Promise.all(
      Object.values(this.feeds).map((feed) => {
        if (feed.items) feed.items = Object.values(feed.items);
      })
    );

    /** IF user is not an applicator then default the ceraApplicatorData
     * object to use the user objects data to prevent empty elements.
     */
    if (this.user.badges.some((badge) => this.ceraApplicatorTypes.includes(badge))) {
      await this.getApplicatorData();
    } else {
      this.ceraApplicatorData = this.user;

      // renames the users' public_work_accepted to the new format of work_accepted.
      delete Object.assign(this.ceraApplicatorData, {
        work_accepted: this.ceraApplicatorData.public_work_accepted.map(
          (label) => label.label
        ),
      })["public_work_accepted"];
    }

    await this.createAggregatedFeeds();
    await this.getFeaturedProjects();

    const getUrlParam = new URLSearchParams(window.location.search);
    const param = getUrlParam.get("tab");

    // check if the url parm exists in the array of tab views.
    const paramMatchesViews = this.views.includes(param);

    if (param && paramMatchesViews) {
      // this will load the appropriate tab on page load.
      this.changeView(param);
    }

    // this checks the middleware feed and updates the load more button if the items are less than 14.
    if (this.currentView !== "") {
      let pageCheck = this.currentView;

      if (pageCheck === "everything") {
        pageCheck = "aggregated";
      }

      const feedsFilter = Object.keys(this.feeds)
        .filter((key) => pageCheck.includes(key))
        .reduce((filteredFeed, key) => {
          filteredFeed[key] = this.feeds[key];
          return filteredFeed;
        }, {});

      const filteredFeedLabel = Object.keys(feedsFilter);
      const filteredFeedItems = Object.values(feedsFilter)[0].items;

      if (filteredFeedItems === undefined) {
        return;
      }

      this.updateButton(...filteredFeedLabel, filteredFeedItems);
    }
  },

  watch: {
    currentView() {
      if (this.currentView === "reviews") {
        this.getReviews();
      }
    },
  },

  methods: {
    async getReviews() {
      // prevent re-fetching b/c of the (watch) when switching tabs.
      if(this.feeds['reviews'].items.length > 0) {
        return;
      }

      const reviewsV2 = `/api/v2/proxify/applicators/${this.applicatorId}/reviews`;
      const queryParams = {
        orderdir: "desc",
        limit: 15,
        domain: this.domain,
        fields: "id,title,body,rating,requestCode,project.url,createdAt,name",
        page: 1
      };

      await axios
        .get(reviewsV2, { params: queryParams })
        .then((response) => {
          if (response.status === 200) {
            if (this.feeds['reviews'].items.length === response.data.length) {
              return;
            }

            this.feeds['reviews'].items.push(...response.data);
            this.updateButton('reviews', this.feeds['reviews'].items);
          }
        })
        .catch((error) => {
          console.error(error);

          this.$refs.notifications.add({
            type: "error",
            icon: "exclamation-triangle",
            title: "Something went wrong",
            message: 'Please contact customer support if you continue having this issue.'
          });
        });
    },

    feedName(feed) {
      return feed.slice(0, -1);
    },

    async getFeaturedProjects() {
      if (!this.feed?.projects.items.length) {
        return;
      }
      return (this.featuredProjects = this.ceraApplicatorData.showcase_projects
        .filter((project) => project.featured_image_url.includes("cerakote"))
        .map((project) => Number(project.id)));
    },

    /**
     *
     * @param {"projects"|"reviews"|"videos"} feed
     * @returns {Promise<void>}
     */

    async orderFeed(feed) {
      this.feeds[feed].items = await this.orderItems(this.feeds[feed].items);
    },

    async orderItems(items, orderBy = "created_at") {
      if (items.length) {
        await Promise.all(
          items.sort((a, b) => (a[orderBy] < b[orderBy] ? 1 : -1))
        );
      }

      return items ?? [];
    },

    async getApplicatorData() {
      let applicator = `/api/proxify/applicators/by-user-id/${this.userId}?domain=cerakote`;

      try {
        let response = await axios.get(applicator);

        this.ceraApplicatorData = response.data;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     *
     * @param {"pinned"|"projects"|"reviews"|"videos"} feed
     * @param {"**"|string[]} fields
     * @param {object} params
     * @returns {Promise<void>}
     * @constructor
     */

    async fetchFeedItems(feed, params = {}, fields = []) {
      let defaultParams = {
        domain: "cerakote",
        orderby: "created_at",
        orderdir: "desc",
        limit: this.feeds[feed].limit,
        page: this.feeds[feed].page
      };

      /**
       * Format the fields query parameter.
       * If no fields were passed, fallback to the ** fields option, which
       * fetches all data associated with the object being requested.
       * If an array of fields is passed, join them together into a comma
       * separated string.
       */

      fields = fields !== "**" && fields.length ? fields.join(",") : "**";

      /**
       * The default query parameters to be passed when requesting data
       * from the specified feed endpoint.
       */

      params = { ...defaultParams, ...{ ...params, ...{ fields } } };

      this.feeds[feed].updating = true;

      if (feed === "pinned") {
        const { data: items } = await axios.get(
          this.endpoints.feed[feed].replace("{id}", this.userId),
          { params }
        );

        this.feeds[feed].items = items.data || items;

        await Promise.all(
          this.feeds[feed].items.map((item) => {
            return this.formatFeedItem(feed, item);
          })
        );
      } else {
        const { data: items } = await axios.get(
          this.endpoints.feed[feed].replace("{id}", this.userId),
          { params }
        );

        await Promise.all(
          this.feeds[feed].items.map((item) => {
            return this.formatFeedItem(feed, item);
          })
        );

        this.feeds[feed].page += 1;

        await this.storeFeedItems(feed, items.data || items);
        await this.orderFeed(feed);
      }

      this.feeds[feed].updating = false;
    },

    async formatFeedItem(feed, item) {
      switch (item.type) {
        case "ProjectEntity":
          item.type = "projects";
          break;

        case "ApplicatorVideo":
          item.type = "videos";
          break;

        case "reviews":
          item.type = "projects";
          break;

        default:
          item.type = feed;
          break;
      }

      return item;
    },

    updateButton(feed, items) {
      const projectFeed = feed === "projects";

      this.disableLoadingButton = false;

      if (this.currentView === "everything") {
        if (projectFeed) {
          if (items.length === 0) {
            this.disableLoadingButton = true;
            return;
          }
        }
      } else if (feed === this.currentView) {
        if (items.length === 0 || items.length < 14) {
          this.disableLoadingButton = true;
          return;
        }
      }
    },

    /**
     * @param {"pinned"|"projects"|"reviews"|"videos"} feed
     * @param {any} items
     * @returns {Promise<void>}
     */

    async storeFeedItems(feed, items) {
      this.updateButton(feed, items);

      await Promise.all(
        items.map(async (item) => {
          // [8/9/2022] - removing the checks that were here when remapping the feed items. See (sc-16187) if we need to revisit this.

          this.feeds[feed].items.push(await this.formatFeedItem(feed, item));

          await this.createAggregatedFeeds();
        })
      );
    },

    /**
     *
     * @param {"pinned"|"projects"|"reviews"|"videos"} feed
     * @param {any} item
     */

    async updateFeedItem(feed, item) {
      await this.addFeedItem(feed, item);
    },

    async addFeedItem(feed, item) {
      // leaving this 👇 here incase the fix below introduces other bugs.
      // this.feeds[feed].items[`${feed}_${item.id}`] = item;

      if (feed) {
        this.feeds[feed].items.unshift(item);
      }

      await this.createAggregatedFeeds();
    },

    /**
     * Removes an item from the specified feed using the provided id.
     * @param {"pinned"|"projects"|"reviews"|"videos"} feed The feed the item is being removed from.
     * @param {number|string} id The item being removed from the specified feed.
     */

    async removeFeedItem([feed, id]) {
      this.feeds[feed].items = this.feeds[feed].items.filter(
        (item) => item.id !== id
      );
    },

    /**
     *
     * @param {"projects"|"reviews"|"videos"} feed
     * @param {number|string} id
     * @returns {Promise<void>}
     */

    async pinFeedItem([feed, id]) {
      try {
        await axios.put(this.endpoints.pin[feed].replace("{id}", id));
        await this.updatePinnedFeed();

        this.$refs.notifications.add({
          type: "success",
          icon: "check",
          title: `${this.feedName(feed)} pinned`,
          message: `The selected ${this.feedName(
            feed
          )} was added to your Featured Work feed.`
        });
      } catch (error) {
        console.error(error);

        this.$refs.notifications.add({
          type: "error",
          icon: "exclamation-triangle",
          title: "Something went wrong",
          message: `Unable to add the selected ${this.feedName(
            feed
          )} to your Featured Work feed.
          Please contact customer support if you continue having this issue.`
        });
      }
    },

    /**
     *
     * @param {"projects"|"reviews"|"videos"} feed
     * @param {number|string} id
     * @returns {Promise<void>}
     */

    async unpinFeedItem([feed, id]) {
      try {
        await axios.delete(this.endpoints.pin[feed].replace("{id}", id));
        await this.updatePinnedFeed();

        this.$refs.notifications.add({
          type: "success",
          icon: "check",
          title: `${this.feedName(feed)} un-pinned`,
          message: `The selected ${this.feedName(
            feed
          )} was removed from your Featured Work feed.`
        });
      } catch (error) {
        this.$refs.notifications.add({
          type: "error",
          icon: "exclamation-triangle",
          title: "Something went wrong",
          message: `Unable to remove the selected ${this.feedName(
            feed
          )} from your Featured Work feed.
          Please contact customer support if you continue having this issue.`
        });
      }
    },

    async updatePinnedFeed() {
      await this.fetchFeedItems("pinned", { pinned_status: "pinned_only" });
    },

    itemIsPinned(feed, id) {
      return (
        this.feeds.pinned.items.filter((item) => {
          return item.type === feed && String(item.id) === String(id);
        }).length > 0
      );
    },

    /**
     *
     * @param {"projects"|"reviews"|"videos"} feed
     * @param {number|string} id
     * @returns {Promise<void>}
     */

    async featureProjectOnMap([feed, id]) {
      let projects = [...[], ...this.featuredProjects];

      if (projects.length < this.featuredProjectsLimit) {
        projects.push(Number(id));

        try {
          await this.updateFeaturedProjects(projects);

          this.$refs.notifications.add({
            type: "success",
            icon: "check",
            title: `Project featured`,
            message: `The selected project is now featured on the applicator map.`
          });
        } catch (error) {
          console.error(error);

          this.$refs.notifications.add({
            type: "error",
            icon: "exclamation-triangle",
            title: "Something went wrong",
            message: `Unable to feature the selected project on the applicator map.
            Please contact customer support if you continue having this issue.`
          });
        }
      } else {
        this.$refs.notifications.add({
          type: "warning",
          icon: "exclamation-triangle",
          title: "Featured project limit reached",
          message: `Unable to feature the selected project on the applicator map.
          You can only pin up to three projects to the applicator map.`
        });
      }
    },

    /**
     *
     * @param {"projects"|"reviews"|"videos"} feed
     * @param {number|string} id
     * @returns {Promise<void>}
     */

    async unfeatureProjectOnMap([feed, id]) {
      let projects = [...[], ...this.featuredProjects];
      projects = projects.filter((project) => {
        return String(project) !== String(id) || Number(project) !== Number(id);
      });

      try {
        await this.updateFeaturedProjects(projects);

        this.$refs.notifications.add({
          type: "success",
          icon: "check",
          title: `Project un-featured`,
          message: `The selected project is no longer featured on the applicator map.`
        });
      } catch (error) {
        console.error(error);

        this.$refs.notifications.add({
          type: "error",
          icon: "exclamation-triangle",
          title: "Something went wrong",
          message: `Unable to un-feature the selected project from the applicator map.
            Please contact customer support if you continue having this issue.`
        });
      }
    },

    async updateFeaturedProjects(projects) {
      await axios.patch(this.endpoints.me, { showcase_project_ids: projects });
      this.featuredProjects = projects;
    },

    /**
     *
     * @param {"projects"|"reviews"|"videos"} feed
     * @param {number|string} id
     * @returns {Promise<void>}
     */
    async deleteFeedItem([feed, id]) {
      if (
        confirm(
          `Are you sure you want to delete the selected ${this.feedName(feed)}?`
        )
      ) {
        try {
          if (this.itemIsPinned(feed, id)) await this.unpinFeedItem([feed, id]);

          await axios.delete(
            this.feeds[feed].endpoints.one.replace("{item.id}", id)
          );
          await this.removeFeedItem([feed, id]);
          await this.createAggregatedFeeds();

          this.$refs.notifications.add({
            type: "success",
            icon: "check",
            title: `${this.feedName(feed)} deleted`,
            message: `The selected ${this.feedName(
              feed
            )} was successfully deleted.`
          });
        } catch (error) {
          console.error(error);

          this.$refs.notifications.add({
            type: "error",
            icon: "exclamation-triangle",
            title: "Something went wrong",
            message: `Unable to delete the selected ${this.feedName(feed)}.
            Please contact customer support if you continue having this issue.`
          });
        }
      }
    },

    /**
     * Alias method for fetching all available items from all available feeds.
     * @returns {Promise<void>}
     */

    async loadMore() {
      try {
        if (this.currentView === "everything") {
          await this.fetchFeedItems("projects");
          await this.fetchFeedItems("videos");
          await this.aggregateFeeds("everything", ["projects", "videos"]);
        }

        if (this.currentView === "projects") {
          await this.fetchFeedItems("projects");
        }

        if (this.currentView === "videos") {
          await this.fetchFeedItems("videos");
        }

        if (this.currentView === "reviews") {
          await this.fetchFeedItems("reviews", {}, ["id","title","body","rating","requestCode","project","createdAt","name"]);
        }
      } catch (error) {
        this.$refs.notifications.add({
          type: "error",
          icon: "exclamation-triangle",
          message: `Unable to load more items. Please contact customer support if you continue having this issue.`
        });
      }
    },

    /**
     * This method takes all items from each provided feed and aggregates them together.
     * Once combined, the aggregated feed is ordered by the `created_at` value.
     * @param {string} aggregate
     * @param {string[]} feeds
     * @returns {[]}
     */

    async aggregateFeeds(aggregate, feeds) {
      this.feedLoaded = false;
      let items = [];

      await Promise.all(
        feeds.map((feed) =>
          this.feeds[feed].items.map((item) => {
            items.push({ ...item, ...{ type: feed } });
          })
        )
      );
      items = await this.orderItems(items);
      this.feeds.aggregated[aggregate] = items;
      this.feedLoaded = true;
    },

    async createAggregatedFeeds() {
      await this.aggregateFeeds("everything", ["projects", "videos"]);
    },

    initProfilePage() {
      this.fetchProfileImages();
    },

    openLoginModal() {
      $("#logInLink").click();
    },

    openEmailModal(applicator) {
      this.applicator = applicator;
      $("#emailApplicatorModal").modal("show");
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    openApplicatorReviewModal(type) {
      if (type !== undefined) {
        this.reviewType = type;
      }

      setTimeout(() => $("#applicatorReviewModal").modal("show"), 150);
    },

    changeView(view) {
      const tabUrl = new URLSearchParams(window.location.search);
      let pageTitle = "";

      this.disableLoadingButton = false;

      if (this.ceraApplicatorData) {
        pageTitle = `${this.ceraApplicatorData.company_name} - ${this.ceraApplicatorData.formatted_address} | ${this.domainName} Certified Applicator`;
      } else {
        pageTitle = document.title;
      }

      // append the url without a page load.
      tabUrl.set("tab", view);
      window.history.replaceState(null, null, `?${tabUrl.toString()}`);

      // update the page title.
      document.title = `${this.capitalizeFirstLetter(view)} for ${pageTitle}`;

      this.currentView = view;

      if (this.currentView !== "everything") {
        this.updateButton(this.currentView, this.feeds[this.currentView].items);
      }
    },

    closeProjectPopup() {
      $("#galleryLightBoxModal").modal("hide");

      setTimeout(() => {
        this.loadingProject = false;
        this.currentProject = false;
        this.currentVideo = false;
        this.currentMedia = 0;
      }, 500);
    },

    closeVideoPopup() {
      $("#galleryVideoModal").modal("hide");
      setTimeout(() => {
        this.currentVideo = false;
      }, 500);
    },
  },
};
